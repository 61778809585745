import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const CardImage4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:py-16 py-8 lg:px-12 md:px-8 px-5">
      <p
        className=" fsize16 text-primary font-medium cust-textColor sm-fsize14 dynamicStyle"
        id="5580655447"
      >
        {data ? data['5580655447'] : 'Lorem Ipsum'}
      </p>
      <h2
        className=" fsize34 md-fsize24 sm-fsize20 font-semibold  cust-textColor dynamicStyle"
        id="9312745632"
      >
        {data ? data['9312745632'] : 'Lorem Ipsum'}
      </h2>
      <div
        ref={elementRef}
        className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
      >
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          className="pb-4 px-2 lg:mt-12 md:mt-12 mt-6"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          }}
        >
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none themeShadow theme-bg '
                  : 'themeShadow theme-bg'
              }
            >
              <div
                id="5829002104"
                className="dynamicStyle2 w-full overflow-hidden rounded-t-lg"
              >
                <Image
                  src={data ? getImage(data['5829002104']) : ''}
                  className="lg:w-full lg:h-80 w-full md:h-72 h-56 rounded-t-lg"
                  alt="women"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                />
              </div>

              <p
                className="px-2 py-3 fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor"
                id="2199402241"
              >
                {data ? data['2199402241'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none themeShadow theme-bg'
                  : 'themeShadow theme-bg'
              }
            >
              <div
                id="9622622483"
                className="dynamicStyle2 w-full overflow-hidden rounded-t-lg"
              >
                <Image
                  src={data ? getImage(data['9622622483']) : ''}
                  className="lg:w-full lg:h-80 w-full md:h-72 h-56 rounded-t-lg"
                  alt="women"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                />
              </div>
              <p
                className="px-2 py-3 fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor"
                id="1634585773"
              >
                {data ? data['1634585773'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none themeShadow theme-bg'
                  : 'themeShadow theme-bg'
              }
            >
              <div
                id="0987311728"
                className="dynamicStyle2 w-full overflow-hidden rounded-t-lg"
              >
                <Image
                  src={data ? getImage(data['0987311728']) : ''}
                  className="lg:w-full lg:h-80 w-full md:h-72 h-56 rounded-t-lg"
                  alt="women"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                />
              </div>
              <p
                className="px-2 py-3 fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor"
                id="8256041892"
              >
                {data ? data['8256041892'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none themeShadow theme-bg'
                  : 'themeShadow theme-bg'
              }
            >
              <div
                id="3490645321"
                className="dynamicStyle2 w-full overflow-hidden rounded-t-lg"
              >
                <Image
                  src={data ? getImage(data['3490645321']) : ''}
                  className="lg:w-full lg:h-80 w-full md:h-72 h-56 rounded-t-lg"
                  alt="women"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                />
              </div>
              <p
                className="px-2 py-3 fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor"
                id="4404486289"
              >
                {data ? data['4404486289'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default CardImage4;
